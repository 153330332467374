/*   */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --primary-font-family: "Manrope", sans-serif;
    --secondary-font-family: "Urbanist", sans-serif;
}

@media screen and (min-width: 992px) {
    #shikshaScrollableDiv::-webkit-scrollbar {
      width: 5px;
      position: absolute;
      left: 0;
    }
    
    #shikshaScrollableDiv::-webkit-scrollbar-track {
      background: transparent;
    }
  
    #shikshaScrollableDiv:hover::-webkit-scrollbar-track {
      background: #b5b7b7;
    }
    
    #shikshaScrollableDiv::-webkit-scrollbar-thumb {
      background: transparent; 
    }
    
    #shikshaScrollableDiv:hover::-webkit-scrollbar-thumb {
      background: #4192a6;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #shikshaScrollableDiv::-webkit-scrollbar {
      width: 5px;
      position: absolute;
      left: 0;
    }
    
    #shikshaScrollableDiv::-webkit-scrollbar-track {
      background: transparent;
    }
  
    #shikshaScrollableDiv:hover::-webkit-scrollbar-track {
      background: #b5b7b7;
    }
    
    #shikshaScrollableDiv::-webkit-scrollbar-thumb {
      background: transparent; 
    }
    
    #shikshaScrollableDiv:hover::-webkit-scrollbar-thumb {
      background: #4192a6;
    }
  }
  
  @media screen and (max-width: 768px) {
    #shikshaScrollableDiv::-webkit-scrollbar {
      width: 5px;
      position: absolute;
      left: 0;
    }
    
    #shikshaScrollableDiv::-webkit-scrollbar-track {
      background: #b5b7b7;
    }
  
    #shikshaScrollableDiv::-webkit-scrollbar-thumb {
      background: #4192a6;
    }
    
  }


  /* Voice-chat page  */

.div1 {
    /* height: 376px; */
    padding: 0;
    /* overflow-y: scroll; */
    /* margin-top: 13px; */
}

.div2 {
    /* height: 375px; */
    padding: 0;
}

.div3 {
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center;
}

.div4 {
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
}

.div5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border-bottom: 1px solid #e0e0e0;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    margin-bottom: 8px;
    width: 100%;
}

.div6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    width: 90%;
}

.span1 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #333;
    width: 56%;
}

.span2 {
    font-size: 0.8rem;
    color: #fff;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    padding: 2px 8px;
    border-radius: 12px;
    margin-left: 10px;
}

.span3 {
    background-color: #4CAF50;
}

.span4 {
    background-color: #FBC02D;
}

.span5 {
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 1.1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 10%;
}

.div7 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
    background-color: rgba(0, 0, 0, 0.5);
}

.div8 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
    border-radius: 46px;
    font-size: 1.5rem;
    border: 1px solid;
    width: 44px;
    height: 35px;
}

.div8:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px;
}

.div9 {
    background-color: hsl(0deg 0% 95.42%);
    min-height: 100vh;
    height: auto;
}

.div10 {
    background-color: #f3f9f9;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    width: 85%;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
}

.div26 {
    padding-top: 14px;
    margin: 0;
    text-align: left;
}

.div11 {
    list-style-type: decimal;
    text-align: left;
    padding-left: 40px;
    margin: 0 auto;
    color: #4192A6;
    font-size: 1rem;
    width: 85%;
    max-width: 600px;
}

.div12 {
    font-size: 0.9rem;
}

.div-popup {
    font-size: 1rem;
}

.error-phn-field{
    font-size: 0.9rem;
    text-align: left;
    color: red;
    margin-left: 18px;
}

.div13 {
    margin: 15px 0;
}

.div14 {
    background-color: #F1F5F9;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    position: relative;
    margin: 10px 0 0 44px;
    height: 86px;
    width: 170px;
}

.div15 {
    font-size: 24px;
    margin-bottom: 5px;
    display: block;
    color: #555;
    margin-left: 25px;
}

.div16 {
    display: block;
    font-size: 1rem;
    color: #3b3939;
    font-weight: 500;
    text-align: left;
}

.div17 {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.h3-1 {
    margin: 0;
    color: #4192A6;
    font-weight: 600;
    font-size: 1.5rem;
}

.div18 {
    margin: 20px 0 0 44px;
    padding-right: 35px;
}

.h4-1 {
    margin-bottom: 5px;
    font-size: 1rem;
}

.li-1 {
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #f44336;
}

.li-2 {
    margin-bottom: 5px;
    font-size: 0.8rem;
}

.button-1 {
    margin-left: 10px;
    color: #f44336;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 1rem;
}

.li-3 {
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #f44336;
}

.div19 {
    margin: 15px 0;
}

.div20 {
    background-color: #F1F5F9;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    position: relative;
    margin: 10px 0 0 44px;
    height: 86px;
    width: 170px;
}

.progress-div-download{
    margin: 14px 0 0 30px;
    border: 1px solid;
    border-radius: 7px;
}

.download-story-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 22px;
}

.icon-1 {
    font-size: 24px;
    margin-bottom: 5px;
    display: block;
    color: #3b3939;
}

.button-2 {
    display: block;
    font-size: 1rem;
    color: #333;
    width: 100%;
    cursor: pointer;
    font-weight: 500;
}

.form-1 {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.input-1 {
    border: 1px solid;
}

.div21 {
    display: none;
}

.button-3 {
    color: #31363F;
}

.div22 {
    z-index: 1000;
}

.aside-1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    background-color: #fff;
}

.div23 {
    border-bottom: 3px solid #EEEEEE;
    padding: 0 0 24px 0;
    border-top: 5px solid #EEEEEE;
}

.div24 {
    font-weight: 600;
    font-size: 1.2rem;
}

.button-4 {
    border: 1px solid;
    border-radius: 21px;
    padding: 8px;
    font-size: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
}

.button-4:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px;
}

.icon-2 {
    display: inline;
}

.button-5 {
    border-top: 5px solid rgb(238, 238, 238);
    width: 100%;
    font-size: 1.3rem;
    padding: 8px;
    color: #000;
}


.div25 {
    color: #f44336;
    font-size: 1rem;
    margin: 7px auto;
    width: 66%;
    font-weight: 500;
}

.loader-wrapper {
    z-index: 2001;
}  

.loader-div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    width: 100vw;  
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0d0d0d;
    overflow: hidden;
    filter: blur(10px);
    z-index: 2000;
}  

.loader-icon {
    font-size: 1.5rem;
    color: #4192A6;
}

.loader-load-spinner{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loader-rotate-loader {
    animation: rotate 1s linear infinite;
}
  
.chat-title-div  {
    cursor: pointer;
}

.chat-title-div:hover  {
    background-color: #f7f7f7;
}

.div27 {
    position: fixed;
    top: 0;
    z-index: 50;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.div70 {
    height: 100%;
    display: block;
}

.div71 {
    display: none;
}

.div28 {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.div29 {
    height: 100%;
}

.div30 {
    padding-left: 4rem;
}

.div30_a{
    padding: 11px 0 !important;
}

.div31 {
    background-color: #ffffff;
    color: #475569;
    border-radius: 59px;
    font-size: 0.8rem;
    padding: 0.75rem;
    margin-top: 0.75rem;
    margin-right: 17px;
    outline: 1px solid #d1d5db;
    outline-offset: 2px;
    width: 100%;
    max-width: 71%;
}

.div32 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0.75rem;
    display: flex;
    background-color: #f9fafb;
    border-radius: 0.375rem;
    align-items: center;
}

.label1 {
    text-align: left;
}

.div33 {
    padding-top: 7rem;
    padding-bottom: 9rem;
}

.div33-a {
    padding-top: 3rem;
    padding-bottom: 9rem;
}


.div34 {
    margin: 0;
}

.div35 {
    padding: 0.25rem;
}

.div36 {
    display: flex;
    align-items: center;
}

.div37 {
    justify-content: flex-end;
}

.div38 {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.div39 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
}

.input-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    outline-color: #0d9488;
    font-size: 0.875rem;
    border-radius: 18px;
    width: 99%;
    min-height: 40px;
    max-height: 150px;
    resize: none;
    overflow-x: hidden;
    color: #475569;
    overflow-y: auto;
    box-sizing: border-box;
    scrollbar-width: none;
    align-content: center;
    padding: 0 14px;
}


.input-2:disabled{
    background-color: #e2e8f0;
    color: #64748b;
}

.textarea-wrapper{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

.button-container {
    display: flex;
    align-items: flex-end;
    justify-content: center; 
}

.button-6 {
    border-radius: 9999px;
    background-color: #f3f8f9 !important;
    padding: 0.75rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1.5rem;
    color: #0d9488;
    height: 3rem;
}

.button-6:disabled{
    background-color: #e2e8f0;
    color: #64748b;
}

.div40 {
    position: absolute;
    right: 80px;
    top: 8px;
    width: 100px;
    border-radius: 9999px;
    background-color: rgba(254, 242, 242, 1) !important;
    font-size: 0.875rem;
    color: #b91c1c;
    padding: 0.75rem;
}

.button-7 {
    border-radius: 9999px;
    padding: 0.9rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1.5rem;
}

.button-8 {
    animation: animate;
    z-index: 10;
    position: absolute;
    right: -30px;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    width: 6rem;
    font-size: 2.25rem;
    background-color: #0d9488 !important;
    color: #ffffff;
}

.button-8:disabled {
    background-color: #e2e8f0;
    color: #64748b;
}

.button-9 {
    background-color: #f3f8f9 !important;
    font-size: 1.25rem;
    color: #0d9488;
}

.div41 {
    display: flex;
    align-items: flex-start;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    position: relative;
}

.div42 {
    padding-left: 0.75rem;
    display: block;
}

.div43 {
    color: #d97706;
}


.div44 {
    color: #0d9488;
}

.div45 {
    font-size: 1.5rem;
}

.div46 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.button-10 {
    background-color: #f9fafb;
    border-radius: 9999px;
    color: #64748b;
    font-size: 1.5rem;
}

.button-11 {
    background-color: #f9fafb;
    border-radius: 9999px;
    color: #64748b;
    font-size: 1.5rem;
}

.div47 {
    padding-right: 1rem;
    width: 100%;
}

.div48 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.div49 {
    color: #0d9488;
    font-size: 1.5rem;
    margin-right: 0.75rem;
}

.div50 {
    border-radius: 0.5rem;
    margin-top: 0.25rem;
    padding-bottom: 0.5rem;
}

.div51 {
    font-size: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-style: italic;
    font-size: 0.875rem;
    color: #9ca3af;
}

.div52 {
    border-radius: 0.5rem;
    margin-top: 0.25rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    width: 100%;
}

.div53 {

    background-color: rgba(255, 251, 235, 1) !important;
}


.div54 {
    background-color: rgba(240, 253, 250, 1) !important;
}

.div55 {
    color: #d97706;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.75rem;
    display: inline;
}

.h6-1 {
    color: #64748b;
    font-size: 0.875rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.div56 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
}

.a-1 {
    cursor: pointer;
    font-size: 0.875rem;
    text-decoration: underline;
    color: #2563eb;
    transition: color 0.2s;
}

.a-1:hover {
    color: #1d4ed8;
}

.a-1:visited {
    color: #7e22ce;
}

.a-1 {
    word-break: break-word;
}

.div57 {
    padding: 1rem;
    border-radius: 0.375rem;
}

.div58 {
    padding: 0.75rem;
    display: flex;
    color: #9ca3af;
}


.clickable-button {
    width: 100%;
    height: 100%;
}


/* Header  */

.header-1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.header-2 {
    padding-top: 3px;
    padding-bottom: 3px;
}

.header-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.div59 {
    display: flex;
    align-items: flex-start;
}

.div60 {
    padding-left: 0.5rem;
}

.img-1 {
    height: 36px;
}

.div61 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
    margin-right: 10px;
}

.div62 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    color: #6b7280;
}

.div63 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    font-weight: 600;
}

.button-12 {
    padding: 0.5rem;
    margin-right: 0.75rem;
}

.icon-3 {
    font-size: 1.875rem;
}

.div64 {
    position: relative;
}

.aside-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.1s;
    font-size: 1.125rem;
    z-index: 1000 !important;
    height: 100%;
}

.aside-3 {
    min-width: 300px;
    color: #ffffff;
    transition-timing-function: ease-in;
    z-index: 20;
}

.aside-4 {
    color: #4b5563;
    transition-timing-function: ease-out;
}

.aside-div {
    flex-grow: 1;
    overflow-y: auto;
}

.icon-4 {
    font-size: 1.5rem;
    color: #000000;
}

.icon-5 {
    font-size: 1.875rem;
    color: #000000;
}

.div65 {
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #000000;
}

.div66 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.icon-6 {
    margin-right: 0.75rem;
}

.div67 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div68 {
    display: block;
}

.div69 {
    display: block;
    padding: 10px 44px;
}

.text-class{
    font-size: 1rem;
    color:#081413;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: var(--primary-font-family);

}

.voice-chat-editor-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.voice-chat-editor-content{
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    position: relative;
}
  
.editor-content-button{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.container-editor-div{
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    height: 500px;
    overflow-y: auto;
}

.editor-main-div{ 
    padding: 0 20px
}

.editor-button-div{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.icon-7{
    font-size: 1.7rem;
    margin-right: 5px;
    margin-bottom: 5px;
    display: block;
    color: #3b3939;
}


.button-13{
    width: 100%;
    text-align: right;
    padding: 20px 24px;
    color: #167fd7;
    font-size: 1.05rem;
}

.div72{
    background-color: hsl(0deg 0% 95.42%);
}

.error-para {
    display: block;
    padding: 0.5rem 3rem;
    color: #cc1c1c;
}