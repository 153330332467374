/* Login.jsx */

.custom-login-image {
    display: block; 
    margin-left: auto; 
    margin-right: auto;
}

.custom-login-text {
    color: blue;
}

/* voice-chat.jsx */

.custom-voice-chat-div{
    height: 100%;
}

.custom-voice-chat-form{ 
    position: fixed;
    bottom: 0; 
    left: 0; 
    width: 100%; 
}

.custom-voice-chat-chats{ 
    max-width: calc(100vw - 75px); 
    padding: 1px 10px;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.login-loader-wrapper {
    z-index: 2001;
}  

.login-loader-div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    width: 100vw;  
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0d0d0d;
    overflow: hidden;
    filter: blur(10px);
    z-index: 2000;
}  

.login-div67 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-loader-icon {
    font-size: 1.5rem;
    color: #4192A6;
}

.login-load-spinner{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.login-rotate-loader {
    animation: rotate 1s linear infinite;
}