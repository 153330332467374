/* story first page  */

.story-company-div1 {
  position: relative;
  width: 210mm;
  height: 296.7mm;
  background: #FFFFFF;
  text-align: center; /* Centers logos horizontally */
}

.story-company-div {
  position: relative;
  width: 210mm;
  height: 296.7mm;
  background: #FFFFFF;
}

.story-company-div-fmt1{
  position: relative;
  width: 210mm;
  height: 296.7mm;
  background: #FEFCF3;
  border: 11px solid #d75d3c;
}

.story-company-text-fmt1{
  position: relative;
  width: 542px;
  height: auto;
  left: calc(50% - 529px / 2);
  top: 30px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3.1rem;
  line-height: 29px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.story-title-fmt1{
  position: relative;
  width: 524px;
  height: auto;
  left: calc(50% - 524px/2);
  top: 79px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  text-align: center;
  letter-spacing: normal;
  color: #000;
}

.story-bg1-fmt1{
  max-width: 495px;
  height: auto;
  top: 216px;
  position: absolute;
  right: -59px;
}

.story-logo-fmt1{
  max-width: 119px;
  height: auto;
  top: 881px;
  position: absolute;
  right: 413px;
}

.story-logo1-fmt1{
  max-width: 182px;
  height: auto;
  top: 912px;
  position: absolute;
  right: 206px;
}

.story-logo2-fmt1{
  max-width: 195px;
  height: auto;
  top: 919px;
  position: absolute;
  left: 207px;
}

.story-logo3-fmt1{
  max-width: 107px;
  height: auto;
  top: 924px;
  position: absolute;
  left: 425px;
}

.story-author-fmt1{
  position: relative;
  width: 370px;
  height: auto;
  left: calc(50% - 370px / 2 + 1px);
  top: 672px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2rem;
  text-align: center;
  color: #000;
}

.story-school-fmt1{
  position: relative;
  width: 370px;
  height: auto;
  left: calc(50% - 370px/2 + 1px);
  top: 706px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 19px;
  text-align: center;
  color: #000;
}

.story-logo-div{
  position: absolute;
  width: 50%; 
  height: 10%;
  left: 25%; 
  top: 5%;
}

.story-logo-div1{
  position: absolute;
  width: 100%; 
  height: 10%;
  top: 5%;
}

.story-company-logo-First{
  position: relative;
  max-width: none;
  right: 190px;
  bottom: 38px;
  width: 800px;
  height: auto;
  display: inline;
}

.story-company-logo{
  position: relative;
  max-width: none;
  right: 290px;
  bottom: 51px;
  width: 736px;
  height: auto;
  display: inline;
}



.story-company-logo1 {
  position: relative;
  max-width: none;
  margin-right: 10px;
  width: 122px;
  height: auto;
  display: inline-block;
}

.story-shikshalokam-logo {
  position: relative;
  max-width: none;
  margin-right: 10px;
  width: 191px;
  height: auto;
  display: inline-block;
}

.story-govt-logo {
  position: relative;
  max-width: none;
  margin-right: 10px;
  width: 90px;
  height: auto;
  display: inline-block;
}

.story-haryana-logo {
  position: relative;
  max-width: none;
  margin-left: 10px; 
  width: 121px;
  height: auto;
  display: inline-block;
}


.story-bg0{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  background: linear-gradient(0.38deg, #4192A6 9.33%, rgba(65, 146, 166, 0) 58.17%);
}

.story-bg1{
  position: absolute;
  width: 588px;
  height: 622px;
  left: calc(50% - 588px/2 + 0.5px);
  top: calc(50% - 622px/2 - 33px);
}

.story-bg2{
  position: absolute;
  width: 470px;
  height: 513px;
  left: calc(50% - 470px/2 + 0.5px);
  top: calc(50% - 513px/2 - 35.5px);
}

.story-title{
  position: relative;
  width: 524px;
  height: auto;
  left: calc(50% - 524px/2);
  top: 898px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

.story-author{
  position: relative;
  width: 370px;
  height: auto;
  left: calc(50% - 370px/2 + 1px);
  top: 931px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}

.story-link{
  position: relative;
  display: block;
  width: 122px;
  height: auto;
  left: calc(50% - 122px/2 + 0.5px);
  top: 956px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #FFFFFF;
}

.story-link1{
  position: relative;
  width: 122px;
  height: auto;
  top: 956px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #FFFFFF;
}

/* story second page  */
.story-company1-div{
  position: relative;
  width: 210mm;
  height: 296.7mm;
  background: linear-gradient(180deg, #EA9C3F 2.21%, #FFFFFF 50.6%);
}

.story-bg4{
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #EA9C3F 2.21%, #FFFFFF 50.6%);
}

.story-heading{
  position: relative;
  width: 100%;
  text-align: left;
  height: auto;
  left: 50px;
  top: 42px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 29px;

  color: #FFFFFF;
  
}

.story-heading-third{
  position: relative;
  width: 100%;
  text-align: left;
  padding-right: 111px;
  height: auto;
  left: 50px;
  top: 42px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 29px;

  color: #000000;
  
}

.story-line-logo1{
  position: relative;
  width: 110px;
  height: 0px;
  left: 50px;
  top: 60.2px;

  border: 2px solid #4192A6;
}
.story-line-logo1-third{
  position: relative;
  width: 110px;
  height: 0px;
  left: 50px;
  top: 60px;

  border: 2px solid #4192A6;
}
.story-contentBox1{
  position: relative;
  width: auto;
  height: auto;
  left: 50px;
  top: 106px;
  
}
.story-tweet1-div{
  margin-top: 64px;
  height: auto;
}

.story-line1-logo1{
  position: relative;
  width: 110px;
  height: 0px;
  border: 2px solid #4192A6;
  transition: top 0.3s ease;
}

.story-tweet-box{
  box-sizing: border-box;
  position: relative;
  width: 640px;
  left: 12px;
  top: 20px;
  background: #FFFFFF;
  border: 2px solid #00A1F6;
  border-radius: 12px;
  display: flex;
  max-height: 250px;
}


/* story third page  */

.story-company2-div{
  position: relative;
  width: 210mm;
  height: 296.7mm;
  background: linear-gradient(0.1deg, #A6C9D5 0%, #FFFFFF 10%);
}

.story-company2-bg{
  width: 100%;
  height: 294mm;
  background: linear-gradient(0.1deg, #A6C9D5 0%, #FFFFFF 10%);
}

.story-contentBox{
  position: relative;
  width: auto;
  height: auto;
  left: 50px;
  top: 81px;
}

.story-line1-logo{
  position: relative;
  width: 110px;
  height: 0px;
  top: 40px;
  
  border: 2px solid #4192A6;
  transition: top 0.3s ease;
}

.story-tweet-box1{
  box-sizing: border-box;
  position: relative;
  width: 640px;
  left: 17px;
  top: 80px;
  max-height: 250px;
  background: #FFFFFF;
  border: 2px solid #00A1F6;
  border-radius: 12px;
  display: flex;
}

.story-twitter-logo{
  position: relative;
  width: 39px;
  height: 40px;
  left: -15px;
  top: -16px;
}

.story-tweet{
  position: relative;
  width: 585px;
  height: auto;
  top: 22px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 28px;
  color: #000000;
  text-align: left;
}

.story-tweet1{
  position: relative;
  width: 585px;
  height: auto;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 28px;
  color: #000000;
  bottom: -16px;
  text-align: left;
}

/* shikshalokam  */
.story-company4-div{
  position: relative;
  width: 210mm;
  height: 296.7mm;
  background: linear-gradient(180deg, #EA9C3F 2.21%, #FFFFFF 50.6%);
}

.story-shikshaLokam-heading {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1.7rem;
  color: #FFFFFF;
  text-align: left;
}

.story-shikshaLokam-contentBox{
  position: relative;
  width: auto;
  height: auto;
  left: 50px;
  top: 106px;
}

.story-shikshaLokam-div {
  position: relative;
  background: linear-gradient(170deg, #D6D1D8 20%, #FFFFFF 50%);
  width: 210mm;
  height: 296.7mm;
}

.story-shikshaLokam-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 15px;
  gap: 23px;
  width: calc(50% - 20px); 
  min-height: 450px; 
  background: #FFFFFF;
  border: 2.4px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  box-sizing: border-box; 
}

.story-subCard{
  height: auto;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.story-shikshaLokam-star{
  width: 28px;
  height: 28.2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.story-shikshaLokam-card-heading{
  width: auto;
  height: 19px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 19px;
  color: #3F8283;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.shikshalokam-line-logo {
  width: 160px;
  height: 0px;
  border: 2px solid #4192A6;
  margin: 17px 2px 29px 2px;
}

.story-fifthpage-wrapper {
  padding: 33px 40px 20px 40px;
}

.story-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
}

.story-shikshaLokam-card-content{
  width: auto;
  height: auto;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  color: #000000;
  flex: none;
  flex-grow: 1;
  order: 2;
  line-height: 1.5rem;
  text-align: left;
  margin-bottom: 20px;
}

.story-shikshaLokam-card-content1{
  width: auto;
  height: auto;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 18px;
  color: #000000;
  line-height: 1.5rem;
}

.story-shikshaLokam-card-line{
  width: 256px;
height: 1px;
border: 3px solid #4192A6;
border-radius: 20px;
flex-shrink: 0; /* Prevent the line from shrinking */
align-self: flex-start;
order: 3;
}

.story-shikshaLokam-card1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 15px;
  gap: 23px;
  position: absolute;
  width: 295px;
  height: auto;
  min-height: 369.21px;
  /* left: 400px;
  top: 176px; */
  left: 400px;
  top: 606px;
  background: #FFFFFF;
  border: 2.4px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;

}

.story-shikshaLokam-card2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 15px;
  gap: 23px;
  position: absolute;
  width: 295px;
  height: auto;
  min-height: 369.21px;
  left: 50px;
  top: 606px;
  background: #FFFFFF;
  border: 2.4px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.story-shikshaLokam-card3{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 15px;
  gap: 23px;
  position: absolute;
  width: 295px;
  height: auto;
  min-height: 369.21px;
  /* left: 400px;
  top: 606px; */
  left: 400px;
  top: 176px;
  background: #FFFFFF;
  border: 2.4px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.load-spinner{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}


.story-tweet-box3 {
  box-sizing: border-box;
  position: relative;
  width: 640px;
  left: 17px;
  top: 80px;
  background: #FFFFFF;
  border: 2px solid #00A1F6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: none;
  overflow: hidden;
}

.story-tweet3 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 28px;
  color: #000000;
}
